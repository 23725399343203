import classes from './Shared.module.scss'

interface IBooking {
  addClass?: string
  style?: string
  version?: string
  text?: string
}

export default function Booking(props: IBooking) {
  return (
    <a
      className={[classes.Booking, classes[props.addClass ?? 'classic'], classes[props.version ?? 'scLinkSm']].join(' ')}
      href="https://www.mysportspace.com/facilityinfo/agcsc"
      target="_blank"
      rel="noreferrer"
      title="Click to Book a court and time slot at AGCSC"
    >
      {props.text ? [props.text] : 'Book Online'}
    </a>
  )
}