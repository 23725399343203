import { Link } from 'react-router-dom'
import classes from './Header.module.scss'
import NavItems from './NavItems'
import Drawer from './Drawer'
import Social from '../Social/Social'
import Email from '../Shared/Email'
import Phone from '../Shared/Phone'
import Booking from '../Shared/Booking'
import logo from '../../assets/images/logo.png'

interface IHeader {
  styled?: string
  navbar?: 'alt'
  addClass?: string
}
export default function Header({addClass, styled, navbar}: IHeader) {
  return (
    <header className={[classes.Header, classes[styled ?? 'stdStyle'], classes[addClass ?? 'stdClass']].join(' ')}>
      <div className={classes.navMm}>
        <div className={[classes.medDef, classes.hdWrap].join(' ')}>
          <div className={classes.mobNav}><Drawer /></div>

          <div className={[classes.hdCol, classes.navBlk, classes.hdEnd].join(' ')}>
            <NavItems navClass="mainNav" />
            <div className={classes.clearfix}/>
            <div className={[classes.hdBottom, classes.hdEmail].join(' ')}><Email where="navBlock" /></div>
          </div>

          <div className={[classes.hdCol, classes.logoBlk].join(' ')}>
            <Link to="/">
              <img src={logo} alt="Alabama Gulf Coast Sports Center logo" className={classes.hdLogo} />
            </Link>
          </div>

          <div className={[classes.hdCol, classes.hdContact, classes.hdEnd].join(' ')}>
            <Link className={classes.conLink} to="/contact">Contact &amp; Location</Link>
            <div className={[classes.hdSocial].join(' ')}><Social where="navBlock" /></div>
            <div className={classes.clearfix}/>
            <div className={[classes.hdBottom, classes.group].join(' ')}>
            <span className={classes.bookLink}><Booking version="navLink" addClass="bold" /></span> <span className={classes.dotLg}>&middot;</span> <span className={classes.phoneTop}><Phone where="navBlock" /></span>
            </div>
          </div>  
        </div>

        <div className={[classes.twWrap, classes[navbar ? 'navTw' : 'medDef']].join(' ')}>
          <div className={[classes.fullSec, classes.navColor].join(' ')}>
            <div className={[classes[navbar ? 'medDef' : 'navTw']].join(' ')}>
              <NavItems navClass="navMid" />
              <div className={[classes.mobContact].join(' ')}>
                <Phone where="mobHd" />
                <span className={[classes.dot, classes.dot1].join(' ')} />
                <Email where="mobHd" />
                <span className={[classes.dot, classes.dot2].join(' ')} />
                <Social where="mobHd" />
              </div>
            </div>
          </div>
        </div>        
      </div>
    </header>
  )
}
